<template lang="pug">
div.details-container
  div.d-flex.content-scrollable-sticky.h-100.justify-content-start.w-100
      #nav-sticky-vertical.text-center.h-100(ref="navStickyVertical" style='overflow-y: auto; overflow-x: hidden; position: relative; background: #EEF1F6; width: 270px; min-width: 270px; max-width: 270px;')
        .close-btn.d-flex.justify-content-end.pt-1.px-1
            span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
              | chevron_left
        ul
          li(v-if="checkFunctionUser(3, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'affair' ? 'current' : ''"  @click="activeTab('affair', tabActive)")
              span.material-icons-outlined.text-primary
                  | work_outline
              span.pl-1.text-nowrap Affaires
          li(v-if="checkFunctionUser(78, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'quote' ? 'current' : ''"  @click="activeTab('quote', tabActive)")
              span.material-icons-outlined.text-primary
                  | description
              span.pl-1.text-nowrap Devis
          li(v-if="checkFunctionUser(111, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'orderFormCustomer' ? 'current' : ''"  @click="activeTab('orderFormCustomer', tabActive)")
              span.material-icons-outlined.text-primary
                  | description
              span.pl-1.text-nowrap Commandes client
          li(v-if="checkFunctionUser(68, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'invoice' ? 'current' : ''"  @click="activeTab('invoice', tabActive)")
              span.material-icons-outlined.text-primary
                  | description
              span.pl-1.text-nowrap Factures
          li(v-if="checkFunctionUser(54, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'credit' ? 'current' : ''"  @click="activeTab('credit', tabActive)")
              span.material-icons-outlined.text-primary
                  | description
              span.pl-1.text-nowrap Avoirs
          li(v-if="checkFunctionUser(27, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'orderFormProvider' ? 'current' : ''"  @click="activeTab('orderFormProvider', tabActive)")
              span.material-icons-outlined.text-primary
                  | shopping_cart
              span.pl-1.text-nowrap Commandes fournisseur
          li(v-if="checkFunctionUser(33, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'receiptFormProvider' ? 'current' : ''"  @click="activeTab('receiptFormProvider', tabActive)")
              span.material-icons-outlined.text-primary
                  | shopping_cart
              span.pl-1.text-nowrap Bons de réception fournisseur
          li(v-if="checkFunctionUser(23, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'invoiceProvider' ? 'current' : ''"  @click="activeTab('invoiceProvider', tabActive)")
              span.material-icons-outlined.text-primary
                  | shopping_cart
              span.pl-1.text-nowrap Factures fournisseur
          li(v-if="checkFunctionUser(18, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'creditProvider' ? 'current' : ''"  @click="activeTab('creditProvider', tabActive)")
              span.material-icons-outlined.text-primary
                  | shopping_cart
              span.pl-1.text-nowrap Avoirs fournisseur
          li(v-if="checkFunctionUser(15, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'deliveryForm' ? 'current' : ''"  @click="activeTab('deliveryForm', tabActive)")
              span.material-icons-outlined.text-primary
                  | shopping_cart
              span.pl-1.text-nowrap Bons de sortie
          li(v-for="(companyType, companyTypeIndex) of companiesTypesList" :key="'companyType_'+companyTypeIndex" v-if="checkFunctionUser(117, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'companyType_'+companyType.id ? 'current' : ''"  @click="activeTab('companyType_'+companyType.id, tabActive, companyType.id)")
              span.material-icons-outlined.text-primary
                  | perm_contact_calendar
              span.pl-1.text-nowrap {{ companyType.label }}
          li(v-if="checkFunctionUser(116, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'contact' ? 'current' : ''"  @click="activeTab('contact', tabActive)")
              span.material-icons-outlined.text-primary
                  | perm_contact_calendar
              span.pl-1.text-nowrap Contacts
          li(v-if="checkFunctionUser(118, 'all')")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'collaborator' ? 'current' : ''"  @click="activeTab('collaborator', tabActive)")
              span.material-icons-outlined.text-primary
                  | people_alt
              span.pl-1.text-nowrap Collaborateurs
      #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 270px)" ref="contentWrapper")
        HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow")
        .d-flex.w-100.h-100(style="overflow: hidden;")
          .text-center.flex-center.w-100(v-if='isLoadingTagsList || isLoadingTagsListArchived || !headerLoaded')
            .loading-bg-inner
              .loader
                .outer
                .middle
                .inner
            .mt-5
              br
              br
              br
              | Chargement de la liste des tags...
          sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :key="gridKey" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowGrouping="allowGrouping")
        popup-tag(:origine="'tag'" :archive="archive" :nature="getNature(tabActive)" :companyTypeId="companyTypeId" ref="popupTag" @refreshGrid="refreshGrid")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import PopupTag from "@/components/parameters/tags/PopupTag.vue";

import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      name: "tag",
      uid: "tags",
      gridKey: 1,
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "quote",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un tag",
      allowGrouping: false,

      companyTypeId: 0,
    };
  },
  created() {
    this.getCompanyTypes({});
    if (this.checkFunctionUser(2, "all")) {
      this.activeTab("affair", "affair");
    } else if (this.checkFunctionUser(4, "all")) {
      this.activeTab("orderFormProvider", "orderFormProvider");
    } else if (this.checkFunctionUser(3, "all")) {
      this.activeTab("affair", "affair");
    }
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "string",
        field: "color",
        headerText: "Couleur",
        width: 60,
        maxWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "colorTemplate",
        visible: true,
        unavailableExport: true,
      },
      {
        type: "string",
        field: "label",
        headerText: "Nom",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  methods: {
    ...mapActions([
      "getCompanyTypes",
      "getCompanyTags",
      "getCompanyTagsArchived",
      "getQuoteTags",
      "getQuoteTagsArchived",
      "getOrderFormCustomerTags",
      "getOrderFormCustomerTagsArchived",
      "getInvoiceTags",
      "getInvoiceTagsArchived",
      "getCreditTags",
      "getCreditTagsArchived",
      "getOrderFormProviderTags",
      "getOrderFormProviderTagsArchived",
      "getDeliveryFormTags",
      "getDeliveryFormTagsArchived",
      "getReceiptFormProviderTags",
      "getReceiptFormProviderTagsArchived",
      "getInvoiceProviderTags",
      "getInvoiceProviderTagsArchived",
      "getCreditProviderTags",
      "getCreditProviderTagsArchived",
      "getAffairTags",
      "getAffairTagsArchived",
      "getCollaboratorTags",
      "getCollaboratorTagsArchived",
      "getContactTags",
      "getContactTagsArchived",
    ]),
    checkFunctionUser,
    setColumnsByObject,
    getNature(tabActive) {
      return tabActive.includes("company") ? "company" : tabActive;
    },
    activeTab(nextTab, previousTab, companyTypeId = 0) {
      this.tabActive = nextTab;
      this.companyTypeId = companyTypeId;
      let tab = nextTab;
      if (nextTab.includes("company")) {
        tab = "company";
      }
      this.fetchListArchivedFunctions(tab).catch(() => {
        this.tabActive = previousTab;
      });
      this.fetchListFunctions(tab).catch(() => {
        this.tabActive = previousTab;
      });
    },
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
    async fetchListFunctions(tab) {
      return await new Promise(async (resolve, reject) => {
        switch (tab) {
          case "company":
            await this.getCompanyTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "quote":
            await this.getQuoteTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "orderFormCustomer":
            await this.getOrderFormCustomerTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "invoice":
            await this.getInvoiceTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "credit":
            await this.getCreditTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "orderFormProvider":
            await this.getOrderFormProviderTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "deliveryForm":
            await this.getDeliveryFormTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "receiptFormProvider":
            await this.getReceiptFormProviderTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "invoiceProvider":
            await this.getInvoiceProviderTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "creditProvider":
            await this.getCreditProviderTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "affair":
            await this.getAffairTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "collaborator":
            await this.getCollaboratorTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "contact":
            await this.getContactTags({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          default:
            break;
        }
      });
    },
    async fetchListArchivedFunctions(tab) {
      return await new Promise(async (resolve, reject) => {
        switch (tab) {
          case "company":
            await this.getCompanyTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "quote":
            await this.getQuoteTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "orderFormCustomer":
            await this.getOrderFormCustomerTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "invoice":
            await this.getInvoiceTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "credit":
            await this.getCreditTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "orderFormProvider":
            await this.getOrderFormProviderTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "deliveryForm":
            await this.getDeliveryFormTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "receiptFormProvider":
            await this.getReceiptFormProviderTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "invoiceProvider":
            await this.getInvoiceProviderTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "creditProvider":
            await this.getCreditProviderTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "affair":
            await this.getAffairTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "collaborator":
            await this.getCollaboratorTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          case "contact":
            await this.getContactTagsArchived({})
              .then(async (res) => {
                if (res.length) {
                  await this.setColumnsByObject(res[0], null, this);
                }
                this.headerLoaded = true;
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;
          default:
            break;
        }
      });
    },
    popupOpenTagEvent(id = 0) {
      let tab = this.tabActive;
      if (this.tabActive.includes("company")) {
        tab = "company";
      }
      this.$bvModal.show("modal-tag-tag");
      this.$refs["popupTag"].popupTagEvent(id, tab);
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.popupOpenTagEvent(0);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.popupOpenTagEvent(event.rowData.id);
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {},
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingTagsList",
      "isLoadingTagsListArchived",
      "tagsList",
      "tagsListArchived",
      "companiesTypesList",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.companyTypeId
          ? this.tagsListArchived.filter(
              (el) => el.companyType.id == this.companyTypeId
            )
          : this.tagsListArchived;
      }
      return this.companyTypeId
        ? this.tagsList.filter((el) => el.companyType.id == this.companyTypeId)
        : this.tagsList;
    },
  },
  components: {
    PopupTag,
    SyncGrid,
    HeaderGrid,
  },
};
</script>

<style lang="scss">
.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
